import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  currentPlanReportAtom,
  useCurrentPlanReportData,
} from "../../../atom/currentPlanReportAtom";
import { useParams } from "react-router-dom";
import { DataState } from "../../../atom/projectsAtom";
import { planReportApi } from "../../../api/planReportApi";
import { DataLoadingStates } from "../../../model/blueprintServer";
import { useData } from "../../../atom/AtomDataClass";

export const useCurrentPlanReport = () => {
  const [currentPlanReport, setCurrentPlanReport] = useRecoilState(
    currentPlanReportAtom,
  );
  const { reportId } = useParams();
  const [interval, setInterval_] = useState<NodeJS.Timer | null>(null);
  const resetCurrentPlanReport = useResetRecoilState(currentPlanReportAtom);
  const planReportData = useCurrentPlanReportData();

  const getPlanReport = useCallback(
    async (reportId: string, showLoader: boolean = true) => {
      if (showLoader) {
        planReportData.startLoading();
      }

      const planReport = await planReportApi.getReport(reportId);
      planReportData.update(planReport).endLoading();

      if (interval) {
        clearInterval(interval);
        setInterval_(null);
      }
    },
    [setCurrentPlanReport, interval],
  );

  useEffect(() => {
    if (!reportId) {
      resetCurrentPlanReport();
      if (interval) {
        clearInterval(interval);
        setInterval_(null);
      }
    }

    if (!reportId || interval) {
      return;
    }

    if (planReportData.nullableData()?.reportId !== reportId) {
      getPlanReport(reportId);
    }
  }, [
    reportId,
    currentPlanReport.model,
    getPlanReport,
    interval,
    resetCurrentPlanReport,
  ]);

  useEffect(() => {
    if (!reportId || !planReportData.isDone()) {
      return;
    }

    if (
      planReportData.data().status === DataLoadingStates.INITIAL_PROCESSING &&
      !interval
    ) {
      const newInterval = setInterval(
        () => getPlanReport(reportId, false),
        3000,
      );
      setInterval_(newInterval);
    }
  }, [
    interval,
    currentPlanReport.model,
    setInterval_,
    reportId,
    getPlanReport,
  ]);
};
