import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonColor } from "../../theme/Button";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "../../app/routerPaths";
import { FaQuestion } from "react-icons/fa";
import { FaLeftLong } from "react-icons/fa6";
import TextareaAutosize from "react-textarea-autosize";

interface QuestionBoxProps {
  handleSubmit: (question: string, onFinish: () => void) => void;
  planId: string;
  projectId: String;
}

export const QuestionBox = ({
  handleSubmit,
  planId,
  projectId,
}: QuestionBoxProps) => {
  const [question, setQuestion] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    textareaRef?.current?.focus();
  }, [textareaRef]);

  const handleChange = (e: any) => {
    setQuestion(e.target.value);
  };

  const handleBack = () => {
    navigate(
      "/" +
        generatePath(ROUTER_PATHS.PLAN, {
          projectId: projectId,
          planId,
        }),
    );
  };

  return (
    <div className="question-box">
      <div className="label">Ask a question</div>
      <TextareaAutosize
        onChange={handleChange}
        maxLength={10000}
        minRows={4}
        value={question}
        ref={textareaRef}
        disabled={loading}
      />
      <div className="flex button">
        <Button
          color={ButtonColor.TRANSPARENT}
          onClick={handleBack}
          IconLeft={FaLeftLong}
        >
          Back
        </Button>
        <Button
          color={ButtonColor.BRAND}
          disabled={question.length < 10 || loading}
          IconLeft={FaQuestion}
          onClick={() => {
            if (question.length < 10) {
              return;
            }
            setLoading(true);
            handleSubmit(question, () => {
              setQuestion("");
              setLoading(false);
            });
          }}
        >
          Ask
        </Button>
      </div>
    </div>
  );
};
