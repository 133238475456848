import { atom } from "recoil";
import { DataState } from "./projectsAtom";
import { OktaUserInfo } from "../model/blueprintServer";
import { User } from "@auth0/auth0-react";

// @ts-ignore
interface UIUser extends OktaUserInfo, User {}

interface ServerAuthFinishedAtom {
  state: DataState;
  appLevelUser: UIUser | undefined;
}

export const serverAuthFinishedAtom = atom<ServerAuthFinishedAtom>({
  key: "auth",
  default: {
    state: DataState.INITIAL,
    appLevelUser: undefined,
  },
});
