import React from "react";
import { Link } from "react-router-dom";

export const Page404 = () => {
  return (
    <div className="no-access">
      <h1>Something went wrong</h1>
      <div>
        If you think this is a mistake, please{" "}
        <Link to="/contact">contact us</Link>.
      </div>
      <img src="/art/dog.png" />
    </div>
  );
};
