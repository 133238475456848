import { BaseApi } from "./BaseApi";
import { PlanQuestion } from "../model/blueprintServer";

class QuestionApi extends BaseApi {
  async ask(inquiryId: string, question: string): Promise<PlanQuestion> {
    return this.get(`ask?inquiryId=${inquiryId}&question=${question}`);
  }

  async getAll(inquiryId: string): Promise<PlanQuestion[]> {
    return this.get(`all?inquiryId=${inquiryId}`);
  }
}

export const questionApi = new QuestionApi("plan-qa/question");
