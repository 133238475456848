import { currenPlanQa, planQaListAtom } from "../../../atom/planQaAtom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect } from "react";
import { DataState } from "../../../atom/projectsAtom";
import { useParams } from "react-router-dom";

export const useCurrentInquery = () => {
  const { planQaId } = useParams();
  const inqueryList = useRecoilValue(planQaListAtom);
  const setCurrentInquery = useSetRecoilState(currenPlanQa);

  useEffect(() => {
    if (!planQaId || inqueryList.state !== DataState.LOADED) {
      return;
    }

    const inquery =
      inqueryList.data.find((inquery) => inquery.planQaId === planQaId) ?? null;
    setCurrentInquery((old) => ({
      ...old,
      data: inquery,
      state: DataState.LOADED,
    }));
  }, [inqueryList, setCurrentInquery, planQaId]);
};
