import { useParams } from "react-router-dom";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { useEffect, useMemo, useState } from "react";
import { planQaListAtom } from "../../../atom/planQaAtom";
import { useLoadPlanQaList } from "./useLoadPlanQaList";
import { useCurrentInquery } from "./useCurrentInquery";
import { useLoadPlanReports } from "./useLoadPlanReports";
import { useCurrentPlanReport } from "../../planReport/hook/useCurrentPlanReport";

export const useCurrentPlan = () => {
  const [currentPlanId, setCurrentPlanId] = useState<string | null>(null);
  const { planId } = useParams();
  const currentProject = useRecoilValue(currentProjectsAtom);
  const resetInqueryList = useResetRecoilState(planQaListAtom);
  const loadPlanQaList = useLoadPlanQaList();
  const loadFullPlanAnalysisList = useLoadPlanReports(true);
  useCurrentInquery();
  useCurrentPlanReport();

  useEffect(() => {
    if (!planId || planId !== currentPlanId) {
      resetInqueryList();
      if (planId && planId !== currentPlanId) {
        setCurrentPlanId(planId);
        loadFullPlanAnalysisList(planId);
        loadPlanQaList(planId);
      }
    }
  }, [
    planId,
    currentPlanId,
    resetInqueryList,
    loadPlanQaList,
    loadFullPlanAnalysisList,
  ]);

  return useMemo(
    () => currentProject.plans.find((p) => p.id === currentPlanId),
    [currentProject.plans, currentPlanId],
  );
};
