import { BaseApi } from "./BaseApi";
import { CityName, PlanReport } from "../model/blueprintServer";

class PlanReportApi extends BaseApi {
  async create(
    planId: string,
    cityName: CityName,
    codeYear: number,
    sections: string[],
  ): Promise<PlanReport> {
    return await this.post(
      `create`,
      { planId, cityName, codeYear, sections },
      {},
      10000 * 6,
    );
  }

  async getReport(reportId: string): Promise<PlanReport> {
    return await this.get(`get?reportId=${reportId}`);
  }

  async getAll(planId: string): Promise<PlanReport[]> {
    return await this.get(`all?planId=${planId}`);
  }
}

export const planReportApi = new PlanReportApi("plan-report");
