import React, { useCallback, useEffect, useState } from "react";
import "./plan.scss";
import { useCurrentPlan } from ".././hook/useCurrentPlan";
import { projectApi } from "../../../api/projectApi";
import { useSetRecoilState } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { formatImageUrl } from "../../../util/imageUtil";
import { Button, ButtonColor } from "../../theme/Button";
import { FaLeftLong, FaPencil, FaRightLong } from "react-icons/fa6";
import { SpinnerLoader } from "../../other/SpinnerLoader";
import { Input } from "../../other/Input";
import { ReviewsMenu } from "./ReviewsMenu";
import { FaPlus } from "react-icons/fa";
import { useCreatePlanQa } from "./useCreatePlanQa";
import { useModal } from "../../theme/useModal";
import { CreateAnalysisModal } from "./CreateAnalysisModal";
import { createPortal } from "react-dom";

export const PlanContainer = () => {
  const plan = useCurrentPlan();
  const [title, setTitle] = useState("");
  const setCurrentProject = useSetRecoilState(currentProjectsAtom);
  const [position, setPosition] = useState(0);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const { showModal } = useModal();

  useEffect(() => {
    setTitle(plan?.title ?? "");
  }, [plan?.title, setTitle]);

  const handleChangeTitle = (title: string) => {
    const planId = plan?.id ?? "";
    projectApi.updatePlanTitle(planId, title);
    setTitle(title);
    setCurrentProject((old) => {
      const newPlans = old.plans.map((plan) => {
        const newPlan = { ...plan };
        if (plan.id === planId) {
          newPlan.title = title;
        }
        return newPlan;
      });
      return { ...old, plans: newPlans };
    });
  };

  const handleTitleInputBlur = () => {
    if (title.length === 0) {
      handleChangeTitle("Untitled plan");
    }
  };

  if (!plan) {
    return null;
  }

  const handleMoveLeft = () => {
    if (position !== 0) {
      setIsLoadingImage(true);
    }
    setPosition((old) => Math.max(0, old - 1));
  };

  const handleMoveRight = () => {
    if (position !== plan?.pages.length - 1) {
      setIsLoadingImage(true);
    }
    setPosition((old) => Math.min(plan?.pages.length - 1 ?? 0, old + 1));
  };

  return (
    <>
      <div className="project-page-container plan-placeholder">
        <div className="flex">
          <ReviewsMenu plan={plan} />
          <div className="content">
            <div className="details flex">
              <Input
                IconLeft={FaPencil}
                className="title-input"
                value={title}
                onChange={handleChangeTitle}
                onBlur={handleTitleInputBlur}
                maxLength={50}
              />
              <Button
                classes="add-button"
                onClick={() => showModal(CreateAnalysisModal)}
                color={ButtonColor.BRAND}
                IconLeft={FaPlus}
              >
                Start a review
              </Button>
            </div>
            <div className="cover-image-wrapper">
              <div className="buttons flex">
                <Button onClick={handleMoveLeft} color={ButtonColor.ICON}>
                  <FaLeftLong />
                </Button>
                <span>
                  {position + 1}/{plan?.pages.length}
                </span>
                <Button onClick={handleMoveRight} color={ButtonColor.ICON}>
                  <FaRightLong />
                </Button>
              </div>
              {isLoadingImage && <SpinnerLoader />}
              <img
                className="cover-image"
                src={formatImageUrl(plan?.pages[position].pageImageBlob)}
                onLoad={() => setIsLoadingImage(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
