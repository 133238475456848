import { useCallback, useEffect, useState } from "react";

export interface PositionOffset {
  top: number;
  left: number;
}

export const usePositionRelativeToParent = (
  parentEl: Element | null,
  offsets: PositionOffset = { top: 0, left: 0 },
  noMaxHeight = false,
) => {
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: -1,
    y: -1,
  });
  const [spaceBellow, setSpaceBellow] = useState(0);
  const [spaceOnRight, setSpaceOnRight] = useState(0);

  const reposition = useCallback(() => {
    if (!parentEl) {
      return;
    }

    const { left, height, top } = parentEl.getBoundingClientRect();

    const bodyRect = document.body.getBoundingClientRect();
    const yPosition = top - bodyRect.top;
    const xPosition = left - bodyRect.left;
    const yOffset = yPosition + height;

    setPosition({ x: xPosition, y: yOffset });
    const spaceBelow = bodyRect.height - yOffset - 20;
    setSpaceBellow(spaceBelow - 20);
    const spaceRight = bodyRect.width - xPosition;
    setSpaceOnRight(spaceRight);
  }, [parentEl]);

  useEffect(() => {
    if (position.x === -1 || position.y === -1) {
      reposition();
    }
  }, [position, reposition]);

  useEffect(() => {
    window.addEventListener("scroll", reposition, true);
    window.addEventListener("resize", reposition, true);
    return () => {
      window.removeEventListener("scroll", reposition, true);
      window.removeEventListener("resize", reposition, true);
    };
  }, [reposition]);

  // @ts-ignore
  return {
    left: position.x - offsets.left,
    top: position.y - offsets.top,
    maxHeight: noMaxHeight ? undefined : spaceBellow,
    position: "fixed" as any,
  };
};
