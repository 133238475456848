import "./userAvatar.scss";
import { useAuth } from "../../hooks/useAuth";
import {
  PositionOffset,
  usePositionRelativeToParent,
} from "../../hooks/usePositionRelativeToParent";
import { useMemo, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, ButtonColor } from "../theme/Button";
import { useRecoilValue } from "recoil";
import { serverAuthFinishedAtom } from "../../atom/serverAuthFinishedAtom";

interface UserAvatarProps {
  tooltipOffset?: PositionOffset;
  paddingLeft?: number;
}

export const UserAvatar = ({
  tooltipOffset = { top: 0, left: 0 },
  paddingLeft = 0,
}: UserAvatarProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const position = usePositionRelativeToParent(
    ref.current,
    tooltipOffset,
    true,
  );
  const { isAuth } = useAuth();
  const [showMenu, setShowMenu] = useState(false);
  const { logout } = useAuth0();
  const { appLevelUser } = useRecoilValue(serverAuthFinishedAtom);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const menu = useMemo(() => {
    if (!showMenu) {
      return;
    }

    return (
      <div
        className="menu"
        style={{ ...position, paddingLeft: `${paddingLeft}px` }}
      >
        <div className="inner">
          <Button
            color={ButtonColor.TRANSPARENT}
            onClick={() => handleLogout()}
          >
            Log out
          </Button>
        </div>
      </div>
    );
  }, [position, showMenu, handleLogout, paddingLeft]);

  const orgAvatar = useMemo(() => {
    if (!appLevelUser?.oktaOrg) {
      return;
    }
    return (
      <div className="org-avatar">
        <img src={appLevelUser.oktaOrg.branding.logo_url} />
      </div>
    );
  }, [appLevelUser]);

  const userAvatar = useMemo(
    () => (
      <div
        className="user-avatar"
        ref={ref}
        onMouseEnter={() => setShowMenu(true)}
        onMouseLeave={() => setShowMenu(false)}
      >
        {appLevelUser?.picture ? (
          <img src={appLevelUser.picture} />
        ) : (
          <span>{appLevelUser?.name?.charAt(0)}</span>
        )}
        {menu}
      </div>
    ),
    [menu, appLevelUser],
  );

  if (!isAuth || !appLevelUser) {
    return null;
  }

  return (
    <div className="avatars flex">
      {orgAvatar}
      {userAvatar}
    </div>
  );
};
