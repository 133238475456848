import { useCurrentPlan } from ".././hook/useCurrentPlan";
import { planReportApi } from "../../../api/planReportApi";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { planReportsAtom } from "../../../atom/fullPlanAnalysisAtom";
import { DataState } from "../../../atom/projectsAtom";
import { useLoadPlanReports } from ".././hook/useLoadPlanReports";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "../../app/routerPaths";

export const useCreatePlanReport = () => {
  const plan = useCurrentPlan();
  const currentProject = useRecoilValue(currentProjectsAtom);
  const setPlanReports = useSetRecoilState(planReportsAtom);
  const loadPlanReports = useLoadPlanReports(true);
  const navigate = useNavigate();

  return async (sections: string[]) => {
    if (!plan || !currentProject.model) {
      return;
    }

    setPlanReports((old) => ({ ...old, state: DataState.LOADING }));
    const planReport = await planReportApi.create(
      plan.id,
      currentProject.model?.jurisdiction.cityName,
      currentProject.model?.jurisdiction.codeYear,
      sections,
    );
    await loadPlanReports(plan.id);
    setPlanReports((old) => ({ ...old, state: DataState.LOADED }));
    navigate(
      generatePath(ROUTER_PATHS.PLAN_REPORT, {
        projectId: currentProject.model.projectId,
        planId: plan.id,
        reportId: planReport.reportId,
      }),
    );
  };
};
