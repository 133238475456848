export const ROUTER_PATHS = {
  HOMEPAGE: "https://blueplanai.com/",
  ROOT: "",
  PROJECTS_CREATE: "/projects/create",
  PROJECT: "project/:projectId",

  PLAN: "project/:projectId/pl/:planId",
  PLAN_QA_ANNOTATION: "project/:projectId/pl/:planId/qa/:planQaId",
  PLAN_QA: "project/:projectId/pl/:planId/qa/:planQaId/question",

  PLAN_REPORT: "project/:projectId/pl/:planId/report/:reportId",

  LOGIN_CALLBACK: "/validate",
  LOGIN: "/login",
  LOGOUT: "/logout",
};
