import React, { useMemo, useState } from "react";
import {
  CityName,
  FinalAssessment,
  SectionComplianceReasoning,
  SectionComplianceReport,
} from "../../model/blueprintServer";
import { useGetBuildingCode } from "../projects/./hook/useGetBuildingCode";
import { Button, ButtonColor } from "../theme/Button";
import { MarkdownCitation } from "../projects/questions/MarkdownCitation";

const Reasoning = ({
  requirement,
  evaluation,
  providedData,
}: SectionComplianceReasoning) => {
  return (
    <div className="reasoning">
      <h4>Reason</h4>
      <h5>Requirement</h5>
      <MarkdownCitation markdown={requirement} />
      <h5>evaluation</h5>
      <MarkdownCitation markdown={evaluation} />
      <h5>providedData</h5>
      <MarkdownCitation markdown={providedData} />
    </div>
  );
};

interface SectionComplianceReportProps {
  sectionReport: SectionComplianceReport;
  section: string;
  cityName: CityName;
}

export const SectionReport = ({
  sectionReport,
  section,
  cityName,
}: SectionComplianceReportProps) => {
  const getBuildingCode = useGetBuildingCode();
  const [isHover, setIsHover] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const additionalClass = useMemo(() => {
    switch (sectionReport.finalAssessment) {
      case FinalAssessment.DEEPER_REVIEW:
        return "deeper-review";
      case FinalAssessment.NO_ENOUGH_DATA:
        return "not-enough-data";
      case FinalAssessment.COMPLIANCE:
        return "compliance";
      case FinalAssessment.NON_COMPLIANCE:
        return "non-compliance";
      case FinalAssessment.ON_SITE_VERIFICATION:
        return "on-site-verification";
      case FinalAssessment.NOT_APPLICABLE:
        return "not-applicable";
    }
  }, [sectionReport.finalAssessment]);

  const row = (
    <tr
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <td>
        {(isHover || showDetails) && (
          <Button
            color={ButtonColor.TRANSPARENT}
            onClick={() => setShowDetails((old) => !old)}
          >
            {showDetails ? "Hide" : "Details"}
          </Button>
        )}
      </td>
      <td>{getBuildingCode(cityName, section)?.fullSectionPath}</td>
      <td>{sectionReport.item}</td>
      <td className={additionalClass}>
        <MarkdownCitation markdown={sectionReport.finalAssessment} />
      </td>
      <td>
        <div>
          <ul>
            {sectionReport.reasoning.map(({ analysis }) => (
              <li>
                <MarkdownCitation markdown={analysis} />
              </li>
            ))}
          </ul>
        </div>
      </td>
    </tr>
  );

  const detailsRow = useMemo(() => {
    if (!showDetails) {
      return null;
    }

    return sectionReport.reasoning.map((reasoning) => (
      <tr className="no-hover">
        <td colSpan={100}>
          <Reasoning {...reasoning} />
        </td>
      </tr>
    ));
  }, [showDetails, sectionReport.reasoning]);

  return (
    <>
      {row}
      {detailsRow}
    </>
  );
};
