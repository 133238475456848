import { atom } from "recoil";
import { DataState } from "./projectsAtom";
import { PlanQA } from "../model/blueprintServer";

interface CurrentPlanQa {
  state: DataState;
  data: PlanQA | null;
}

export const currenPlanQa = atom<CurrentPlanQa>({
  key: "planQaAtom",
  default: {
    state: DataState.NOT_LOADED,
    data: null,
  },
});

interface PlanQaList {
  state: DataState;
  data: PlanQA[];
}

export const planQaListAtom = atom<PlanQaList>({
  key: "InqueryList",
  default: {
    state: DataState.NOT_LOADED,
    data: [],
  },
});
