import React, { useRef, useState } from "react";
import { Link, matchPath } from "react-router-dom";
import "./projects.scss";
import { ROUTER_PATHS } from "../../app/routerPaths";
import { BiHardHat } from "react-icons/bi";
import { usePositionRelativeToParent } from "../../../hooks/usePositionRelativeToParent";
import { UserAvatar } from "../../app/UserAvatar";

const matchListOfRoutes = (list: string[], currentPath: string) =>
  list.some(
    (path) =>
      matchPath(
        {
          path,
        },
        currentPath,
      ) !== null,
  );

interface MenuItemProps {
  link: string;
  text: string;
}

const MenuItem = ({ link, text }: MenuItemProps) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const position = usePositionRelativeToParent(ref.current, {
    left: -50,
    top: 30,
  });
  const [tooltip, setTooltip] = useState(false);

  return (
    <Link
      to={link}
      className="item title clickable selected"
      ref={ref}
      onMouseEnter={() => setTooltip(true)}
      onMouseLeave={() => setTooltip(false)}
    >
      {tooltip && (
        <div className="tooltip" style={{ ...position }}>
          {text}
        </div>
      )}
      <BiHardHat />
    </Link>
  );
};

export const AppSideMenu = () => {
  return (
    <div className="flex side-menu">
      <Link to="/" className="flex">
        <img src="/logo-inverted.png" className="logo" alt="header fydelity" />{" "}
      </Link>
      <div className="items flex">
        <MenuItem link={ROUTER_PATHS.ROOT} text="Projects" />
      </div>
      <UserAvatar tooltipOffset={{ top: 48, left: -30 }} paddingLeft={20} />
    </div>
  );
};
