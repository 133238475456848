import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import "./uploadContainer.scss";

interface UploadBluePrintPdfProps {
  handleFile: (file: File) => void;
}

export const UploadContainer = ({ handleFile }: UploadBluePrintPdfProps) => {
  const [active, setActive] = useState(false);
  const onDropAccepted = (files: any[]) => {
    setActive(false);
    if (!files.length) {
      return;
    }
    handleFile(files[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDragEnter: () => setActive(true),
    onDragLeave: () => setActive(false),
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  const className = `dropzone${active ? " active" : ""}`;

  return (
    <section className="container">
      <div {...getRootProps({ className: className })}>
        <input {...getInputProps()} />
        <p>Drop a .pdf plan file</p>
      </div>
    </section>
  );
};
