import React, { useState } from "react";
import { CityName } from "../../model/blueprintServer";
import { Dropdown } from "../other/Dropdown";
import { PlanReport } from "../planReport/PlanReport";
import { useGenerateReport } from "../planReport/useGenerateReport";

export const AdminReportUI = () => {
  const [sections, setSections] = useState("");
  const [planId, setPlanId] = useState("");
  const [cityName, setCityName] = useState<CityName>(CityName.NEW_YORK_CITY);
  const [codeYear, setCodeYear] = useState<string>("2022");
  const { isLoading, data, generate } = useGenerateReport();

  const handleRun = async () => {
    const sectionsList = sections.replace(/\s/g, "").split(",");
    generate(planId, cityName, codeYear, sectionsList);
  };

  return (
    <div>
      <div>
        <input
          value={planId}
          onChange={(e) => setPlanId(e.target.value)}
          placeholder="PlanId"
        />
        <input
          value={sections}
          onChange={(e) => setSections(e.target.value)}
          placeholder="sections"
        />
        <input
          value={codeYear}
          onChange={(e) => setCodeYear(e.target.value)}
          placeholder="code year"
        />
        <Dropdown
          value={cityName}
          values={[CityName.NEW_YORK_CITY, CityName.PHILADELPHIA]}
          onChange={setCityName}
        />
        <button onClick={handleRun}>Run</button>
      </div>
      <div>
        {isLoading && <div>Loading</div>}
        {data && <PlanReport report={data} cityName={cityName} />}
      </div>
    </div>
  );
};
