import React from "react";
import {
  CityName,
  PlanReport as PlanReportModel,
} from "../../model/blueprintServer";
import { SectionReport } from "./SectionReport";
import "./planReport.scss";

interface PlanReport {
  report: PlanReportModel;
  cityName: CityName;
}

export const PlanReport = ({ report, cityName }: PlanReport) => {
  const sectionsRows = Object.entries(report.reportMap).map(
    ([buildingCode, sectionReport], index) => (
      <SectionReport
        sectionReport={sectionReport}
        section={buildingCode}
        cityName={cityName}
        key={index}
      />
    ),
  );

  return (
    <div className="plan-report">
      <table>
        <thead>
          <tr>
            <th className="expend">Section</th>
            <th className="section">Section</th>
            <th className="name">Name</th>
            <th className="status">Status</th>
            <th className="code">Details</th>
          </tr>
        </thead>
        <tbody>{sectionsRows}</tbody>
      </table>
    </div>
  );
};
