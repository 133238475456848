import React, { useMemo, useRef, useState } from "react";
import { marked } from "marked";
import { Citation, detectSpecialLinks } from "./parseCitationUtil";
import parse, { DOMNode, Element, Text } from "html-react-parser";
import { BuildingCodeCard } from "./BuildingCodeCard";
import "./markdownCitation.scss";
import { useFetchBuildingCode } from ".././hook/useFetchBuildingCode";

interface MountHtmlProps {
  markdown: string;
}

interface CitationLinkProps {
  text: string;
  citation: Citation;
}

const CitationLink = ({ text, citation }: CitationLinkProps) => {
  const [showCard, setShowCard] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const card = (
    <BuildingCodeCard
      parentEl={ref.current}
      source={{ section: citation.section, sectionTitle: "" }}
      city={citation.cityName}
      year={citation.year}
    />
  );

  return (
    <span
      className="markdown-citation"
      onMouseEnter={() => setShowCard(true)}
      onMouseLeave={() => setShowCard(false)}
      ref={ref}
    >
      {showCard && card}
      {text}
    </span>
  );
};

const hasDataCitation = (element: Element) =>
  !!element.attributes.find((a) => a.name === "data-citation");

const replaceFunction = (domNode: DOMNode) => {
  const el = domNode as Element;
  if (el.attribs && el.tagName === "span") {
    const dataAtt = el.attributes.find((a) => a.name === "data-citation");
    const next = el.next as Text;
    if (dataAtt) {
      const citation = JSON.parse(dataAtt.value) as Citation;
      return (
        <CitationLink
          citation={citation}
          text={(el.children[0] as Text).data}
        />
      );
    } else if (hasDataCitation(el)) {
      return null;
    }
  }
  return domNode;
};

export const MarkdownCitation = ({ markdown }: MountHtmlProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const fetchBuildingCode = useFetchBuildingCode();

  const content = useMemo(() => {
    const { text, citations } = detectSpecialLinks(markdown);
    // TODO: send with each section the city and year. Now we assume they are from the same city+year.
    if (citations.length !== 0) {
      const cityName = citations[0].cityName;
      const year = citations[0].year;
      const sections = citations.map((c) => c.section);
      fetchBuildingCode(cityName, year, sections);
    }

    if (!text) {
      return null;
    }

    const markdownHtml = marked.parse(text) as string;
    return parse(markdownHtml, {
      replace: replaceFunction,
    });
  }, [markdown, fetchBuildingCode]);

  return <div ref={ref}>{content}</div>;
};
