import React, { useMemo } from "react";
import classNames from "classnames";
import { AppOutlet } from "../projects/AppOutlet";
import { useRecoilValue } from "recoil";
import { serverAuthFinishedAtom } from "../../atom/serverAuthFinishedAtom";
import { DataState } from "../../atom/projectsAtom";
import { AppLogoLoader } from "../other/AppLogoLoader";
import { useModal } from "../theme/useModal";

export const PageBehindAuth = () => {
  const { state } = useRecoilValue(serverAuthFinishedAtom);
  const { modalComponent } = useModal();
  const outletWithProject = useMemo(() => {
    if (state === DataState.LOADING || state === DataState.INITIAL) {
      return <AppLogoLoader />;
    } else if (state === DataState.LOADED) {
      return <AppOutlet />;
    }
  }, [state]);

  return (
    <div className="app-behind-loader">
      {modalComponent}
      <div className={classNames("outlet")} id="outlet">
        {outletWithProject}
      </div>
    </div>
  );
};
