import React, { useMemo } from "react";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { getCityId } from "../../../util/addressUtil";
import { useRecoilValue } from "recoil";
import { DataState, projectsAtom } from "../../../atom/projectsAtom";
import { MultiLinearLoader } from "../../other/MultiLinearLoader";
import { EmptyContainer } from "../../other/EmptyContainer";
import { ROUTER_PATHS } from "../../app/routerPaths";
import { AiFillPlusCircle } from "react-icons/ai";
import { Button, ButtonColor, ButtonSize } from "../../theme/Button";
import { ProjectModel } from "../../../model/blueprintServer";
import {
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FaExternalLinkAlt } from "react-icons/fa";
import { DebouncedInput } from "../../other/DebounceInput";
import { rankItem } from "@tanstack/match-sorter-utils";
import "./projects.scss";

const columnHelper = createColumnHelper<ProjectModel>();

const columns = [
  columnHelper.accessor("projectId", {
    id: "open",
    header: () => <span />,
    cell: (info) => (
      <Link
        key={info.getValue()}
        to={generatePath(ROUTER_PATHS.PROJECT, { projectId: info.getValue() })}
        className="link"
      >
        <FaExternalLinkAlt />
      </Link>
    ),
  }),
  columnHelper.accessor("address", {
    header: () => <span>Address</span>,
    cell: (info) => {
      return (
        <Link
          to={generatePath(ROUTER_PATHS.PROJECT, {
            projectId: info.row.original.projectId,
          })}
        >
          {info.row.original.address.formatted}
        </Link>
      );
    },
  }),
  // @ts-ignore
  columnHelper.accessor("cityId", {
    header: () => <span>City Id</span>,
    cell: (info) => getCityId(info.row.original.address),
  }),
  columnHelper.accessor("created", {
    header: () => <span>Created</span>,
    cell: (info) => new Date(info.getValue()).toDateString(),
  }),
];

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
};

export const Projects = () => {
  const projectsStore = useRecoilValue(projectsAtom);
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = React.useState("");
  const table = useReactTable({
    data: projectsStore.list,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: "includesString",
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      globalFilter,
    },
  });

  const projectsItems = useMemo(
    () => (
      <table className="list">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    ),
    [projectsStore.list, table],
  );

  const projectsList = (
    <div className="projects-list">
      <h2 className="flex">
        Projects{" "}
        <Button
          size={ButtonSize.SMALL}
          color={ButtonColor.BRAND}
          onClick={() => navigate(ROUTER_PATHS.PROJECTS_CREATE)}
          IconLeft={AiFillPlusCircle}
        >
          New project
        </Button>
      </h2>
      <DebouncedInput
        value={globalFilter}
        onChange={(value) => setGlobalFilter(String(value))}
        placeholder="Search projects..."
      />
      {projectsStore.state === DataState.LOADING && <MultiLinearLoader />}
      {projectsStore.state === DataState.LOADED && projectsStore.list.length ? (
        projectsItems
      ) : (
        <EmptyContainer>No projects</EmptyContainer>
      )}
    </div>
  );

  return (
    <div className="projects">
      <div className="top-bar"></div>
      {projectsList}
    </div>
  );
};
