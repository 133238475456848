import { useEffect } from "react";
import { useAuth } from "./useAuth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthorizationParams } from "@auth0/auth0-spa-js/src/global";
import { useAuth0 } from "@auth0/auth0-react";
import { serverAuthFinishedAtom } from "../atom/serverAuthFinishedAtom";
import { useRecoilState } from "recoil";
import { DataState } from "../atom/projectsAtom";

export const useAppWithAuth = () => {
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { doAuth } = useAuth();
  const [serverAuthState, setServerAuthState] = useRecoilState(
    serverAuthFinishedAtom,
  );

  useEffect(() => {
    if (
      serverAuthState.state === DataState.LOADED ||
      serverAuthState.state === DataState.LOADING ||
      serverAuthState.state === DataState.NOT_LOADED
    ) {
      return;
    }

    setTimeout(() => doAuth(), 100);
  }, [doAuth]);

  useEffect(() => {
    const authorizationParams: AuthorizationParams = {};
    if (searchParams.get("organization")) {
      authorizationParams.organization = searchParams.get(
        "organization",
      ) as string;
    }

    if (searchParams.get("invitation")) {
      authorizationParams.invitation = searchParams.get("invitation") as string;
    }

    if (authorizationParams.invitation && authorizationParams.organization) {
      loginWithRedirect({ authorizationParams });
    }
  }, [searchParams, loginWithRedirect, navigate]);
};
