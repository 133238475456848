import { useSetRecoilState } from "recoil";
import { useCallback } from "react";
import { DataState } from "../../../atom/projectsAtom";
import { planReportsAtom } from "../../../atom/fullPlanAnalysisAtom";
import { planReportApi } from "../../../api/planReportApi";

export const useLoadPlanReports = (showLoadingState: boolean) => {
  const setFullPlanAnalysisList = useSetRecoilState(planReportsAtom);

  return useCallback(
    async (planId: string) => {
      if (showLoadingState) {
        setFullPlanAnalysisList((old) => ({
          ...old,
          state: DataState.LOADING,
        }));
      }

      const planReports = await planReportApi.getAll(planId);
      setFullPlanAnalysisList(() => ({
        data: planReports,
        state: DataState.LOADED,
      }));
    },
    [setFullPlanAnalysisList, showLoadingState],
  );
};
