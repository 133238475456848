import React, { useCallback } from "react";
import { Breadcrumb } from "antd";
import { Link, useLocation, matchPath, generatePath } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { projectsAtom } from "../../atom/projectsAtom";
import { currenPlanQa } from "../../atom/planQaAtom";
import { useCurrentPlan } from "../projects/./hook/useCurrentPlan";
import { LinearLoader } from "../other/LinearLoader";
import { planReportsAtom } from "../../atom/fullPlanAnalysisAtom";
import { ROUTER_PATHS } from "./routerPaths";
import { useCurrentPlanReportData } from "../../atom/currentPlanReportAtom";

const IGNORE_STRINGS = ["pl", "qa", "report"];

const TEXT_MAP: { [key: string]: string } = {
  project: "Projects",
  question: "Q&A",
  [ROUTER_PATHS.ROOT]: "Projects",
};

const getFragmentsIdsLocations = (url: string) => {
  if (matchPath({ path: ROUTER_PATHS.ROOT }, url)) {
    return [false];
  }

  const path = Object.values(ROUTER_PATHS).find((path) =>
    matchPath({ path }, url),
  );

  if (!path) {
    return [];
  }

  return path.split("/").map((f) => f.startsWith(":"));
};

export const Breadcrumbs = () => {
  const location = useLocation();
  const projectStore = useRecoilValue(projectsAtom);
  const currentPlan = useCurrentPlan();
  const planQA = useRecoilValue(currenPlanQa);
  const planReports = useRecoilValue(planReportsAtom);
  const planReportData = useCurrentPlanReportData();

  const getUrlForPlan = (someId: string, isId: boolean) => {
    if (someId === "project") {
      return generatePath(ROUTER_PATHS.ROOT);
    }

    if (!isId) {
      return location.pathname;
    }

    const project = projectStore.list.find(
      (project) => project.projectId === someId,
    );

    if (project) {
      return generatePath(ROUTER_PATHS.PROJECT, {
        projectId: project.projectId,
      });
    }

    if (!currentPlan) {
      return location.pathname;
    }

    if (currentPlan.id === someId) {
      return generatePath(ROUTER_PATHS.PLAN, {
        projectId: currentPlan.projectId,
        planId: currentPlan.id,
      });
    }

    if (planReportData.nullableData()?.reportId === someId) {
      return generatePath(ROUTER_PATHS.PLAN_REPORT, {
        projectId: currentPlan.projectId,
        planId: currentPlan.id,
        reportId: someId,
      });
    }

    return location.pathname;
  };

  const getSomeIdData = useCallback(
    (someId: string) => {
      const project = projectStore.list.find(
        (project) => project.projectId === someId,
      );
      if (project) {
        return <span>{project.address.formatted}</span>;
      }

      if (currentPlan && currentPlan.id === someId) {
        return <span>{currentPlan.title}</span>;
      }

      if (planQA.data && planQA.data.planQaId === someId) {
        return <span>{planQA.data.title}</span>;
      }

      const planReport = planReports.data.find((a) => a.reportId === someId);

      if (planReport) {
        return <span>{planReport.title}</span>;
      }

      return <LinearLoader />;
    },
    [projectStore.list, planQA.data, planReports.data, currentPlan],
  );

  const fragments = location.pathname.split("/");
  // The pathname starts with an empty string.
  fragments.shift();

  return (
    <Breadcrumb style={{ margin: "4 0 8px 0" }}>
      {getFragmentsIdsLocations(location.pathname).map((isId, index) => {
        const fragment = fragments[index];
        if (IGNORE_STRINGS.includes(fragment)) {
          return null;
        }

        let text = <span>{TEXT_MAP[fragment] ?? fragment}</span>;
        if (isId) {
          text = getSomeIdData(fragment);
        }

        return (
          <Breadcrumb.Item key={index}>
            <Link to={getUrlForPlan(fragment, isId)} className="breadcrumb">
              {text}
            </Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};
