import React, { useEffect, useMemo, useRef, useState } from "react";
import { PlanQA, PlanModel } from "../../../model/blueprintServer";
import { formatImageUrl } from "../../../util/imageUtil";
import "./planViewer.scss";
import classNames from "classnames";
import { PlanCanvas } from "./PlanCanvas";
import { useRecoilValue } from "recoil";
import { DataState } from "../../../atom/projectsAtom";
import { currenPlanQa } from "../../../atom/planQaAtom";
import { pageImageSizeAtom } from "../../../atom/pageImageSizeAtom";

interface PlanViewerProps {
  plan: PlanModel;
}

export const PlanViewer = ({ plan }: PlanViewerProps) => {
  const inquery = useRecoilValue(currenPlanQa);
  const [pageNumber, setPageNumber] = useState(0);
  const [renderedInquery, setRenderedInquery] = useState<PlanQA | null>(null);
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const { adjustedContainerHeight } = useRecoilValue(pageImageSizeAtom);

  useEffect(() => {
    if (renderedInquery?.planQaId !== inquery.data?.planQaId) {
      setPageNumber(0);
      setRenderedInquery(inquery.data ?? null);
      toolbarRef?.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [renderedInquery, setPageNumber, setRenderedInquery, inquery, toolbarRef]);

  const sidebar = useMemo(() => {
    return plan.pages.map((page, index) => (
      <div
        className={classNames("item", { selected: pageNumber === index })}
        onClick={() => setPageNumber(index)}
        key={page.id}
      >
        <img src={formatImageUrl(page.thumbnailBlob)} />
        <div className="index">{index + 1}</div>
      </div>
    ));
  }, [plan, pageNumber, setPageNumber]);

  const imageBlob = useMemo(
    () => plan.pages[pageNumber].pageImageBlob,
    [plan, pageNumber],
  );

  const isLoaded = imageBlob && inquery.state === DataState.LOADED;
  return (
    <div className="flex plan-viewer">
      <div
        className="sidebar flex"
        style={{ height: adjustedContainerHeight }}
        ref={toolbarRef}
      >
        {sidebar}
      </div>
      {isLoaded && inquery.data && (
        <PlanCanvas
          pageImageBlob={imageBlob}
          pageNumber={pageNumber}
          inquery={inquery.data}
        />
      )}
    </div>
  );
};
