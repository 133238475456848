import { BaseApi } from "./BaseApi";
import { OktaUserInfo } from "../model/blueprintServer";

class LoginApi extends BaseApi {
  async auth(token: string): Promise<OktaUserInfo> {
    localStorage.setItem("accessToken", token);
    return await this.get<OktaUserInfo>("", token);
  }

  async logout() {
    return await this.get("logout");
  }
}

export const authApi = new LoginApi("user");
