import React, { useRef } from "react";
import { AppSideMenu } from "./project/AppSideMenu";
import { Outlet } from "react-router-dom";
import { useCurrentProject } from "././hook/useCurrentProject";
import { useLoadProjects } from "././hook/useLoadProjects";
import "./projectStyle.scss";
import { useRecoilState } from "recoil";
import { currentProjectsAtom } from "../../atom/currentProjectAtom";
import classNames from "classnames";
import { Breadcrumbs } from "../app/Breadcrumbs";
import { usePollPlans } from "././hook/usePollPlans";
import { getCityId } from "../../util/addressUtil";
import { useFinishLoadInitiateData } from "../../hooks/useFinishLoadInitiateData";
import { AppLogoLoader } from "../other/AppLogoLoader";

export const AppOutlet = () => {
  const [currentProject, setCurrentProject] =
    useRecoilState(currentProjectsAtom);
  const ref = useRef<HTMLDivElement>(null);
  const finishLoadInitiateData = useFinishLoadInitiateData();
  useLoadProjects();
  useCurrentProject();
  usePollPlans();

  if (!finishLoadInitiateData) {
    return <AppLogoLoader />;
  }

  return (
    <div className="flex outlet-project-container">
      <AppSideMenu />
      <div className="outlet-container" id="outlet-container" ref={ref}>
        <div className={classNames("project-header flex")}>
          <div className="flex address-container">
            <div className="flex address">
              <Breadcrumbs />
            </div>
            <div className="bin flex">
              {currentProject.model
                ? getCityId(currentProject.model.address)
                : null}
            </div>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};
