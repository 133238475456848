import { atom } from "recoil";
import { DataState } from "./projectsAtom";
import { PlanReport } from "../model/blueprintServer";

interface SectionsComplianceList {
  state: DataState;
  data: PlanReport[];
}

export const planReportsAtom = atom<SectionsComplianceList>({
  key: "planQaListAtom",
  default: {
    state: DataState.NOT_LOADED,
    data: [],
  },
});
