import { BaseApi } from "./BaseApi";
import { PlanModel, PlanQA } from "../model/blueprintServer";

class TempApi extends BaseApi {
  constructor() {
    super("admin");
  }

  markComplete(planId: string) {
    return this.get(`mark-complete?planId=${planId}`);
  }

  copyAnnotations(planId: string, planQaId: string) {
    return this.get(
      `inject-page-components?planId=${planId}&planQaForAnnotationId=${planQaId}`,
    );
  }

  planPages(planId: string): Promise<PlanModel> {
    return this.get(`get-pages?planId=${planId}`);
  }

  async getPlanQa(planQaId: string): Promise<PlanQA[]> {
    return this.get(`get-plan-qa?planQaId=${planQaId}`);
  }
}

export const adminApi = new TempApi();
