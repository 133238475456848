import React, { useCallback, useMemo, useState } from "react";
import "./createAnalysisModal.scss";
import { useCreatePlanQa } from "./useCreatePlanQa";
import { ModalContentComponent } from "../../theme/useModal";
import { CreatePlanReport } from "./CreatePlanReport";

export const CreateAnalysisModal = ({ handleClose }: ModalContentComponent) => {
  const createInquery = useCreatePlanQa();
  const [showChapterSelector, setShowChapterSelector] = useState(false);

  const handleCreatePlanQa = useCallback(() => {
    createInquery();
    handleClose();
  }, [handleClose, createInquery]);

  const handleFullPlanAnalysis = useCallback(() => {
    setShowChapterSelector(true);
  }, [setShowChapterSelector]);

  return useMemo(() => {
    if (showChapterSelector) {
      return <CreatePlanReport handleClose={handleClose} />;
    }

    return (
      <div className="create-analysis-modal">
        <div className="description">Select the analysis type</div>
        <div className="flex buttons">
          <button onClick={handleCreatePlanQa}>
            <div className="title">Q&A</div>
            <div>Ask question on the plan</div>
          </button>
          <button onClick={handleFullPlanAnalysis} disabled={true}>
            <div className="title">Full plan analysis</div>
            <div>Find zoning and code issue</div>
          </button>
        </div>
      </div>
    );
  }, [
    handleCreatePlanQa,
    handleFullPlanAnalysis,
    showChapterSelector,
    handleClose,
  ]);
};
