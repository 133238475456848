import { PlanModel, ProjectModel } from "../model/blueprintServer";
import { BaseApi } from "./BaseApi";

class ProjectApi extends BaseApi {
  async create(placeId: string): Promise<ProjectModel> {
    return this.get(`create?placeId=${placeId}`);
  }

  async getAll(): Promise<ProjectModel[]> {
    return this.get("all");
  }

  updateProjectDetails(projectModel: ProjectModel) {
    this.post("update", projectModel);
  }

  updatePlanTitle(planId: string, title: string) {
    this.get(`update-title?planId=${planId}&title=${title}`);
  }
}

export const projectApi = new ProjectApi("project");
