import React from "react";
import { useCurrentPlanReportData } from "../../atom/currentPlanReportAtom";
import { useRecoilValue } from "recoil";
import { AppLogoLoader } from "../other/AppLogoLoader";
import { assertDefined } from "../../util/assertions";
import { DataLoadingStates } from "../../model/blueprintServer";
import { PlanReport } from "./PlanReport";
import { currentProjectsAtom } from "../../atom/currentProjectAtom";

export const PlanReportPage = () => {
  const currentProject = useRecoilValue(currentProjectsAtom);
  const planReportData = useCurrentPlanReportData();

  if (planReportData.isLoading() || !currentProject.model) {
    return <AppLogoLoader />;
  }
  console.log(planReportData);
  if (planReportData.data().status !== DataLoadingStates.COMPLETED) {
    return <div>Creating report, we will email you when it is ready</div>;
  }

  const project = assertDefined(currentProject.model);

  return (
    <div>
      <h2>Report</h2>
      <div>
        Created {new Date(planReportData.data().created).toDateString()}
      </div>
      <PlanReport
        report={planReportData.data()}
        cityName={project.jurisdiction.cityName}
      />
    </div>
  );
};
