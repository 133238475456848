import { BuildingCodeTreeNode } from "../../util/createBuildingCodeSectionTree";
import { useEffect, useMemo, useState } from "react";
import "./nestedList.scss";
import classNames from "classnames";

function traverseAndToggleNode(
  data: BuildingCodeTreeNode[],
  value: string,
  onChange: (node: BuildingCodeTreeNode) => void,
  key: "isSelected" | "isDisabled" | "isCollapsed" = "isSelected",
) {
  let toggled = false;

  if (data) {
    data.forEach((node) => {
      if (node.value === value) {
        node[key] = !node[key];
        toggled = true;
        onChange(node);
      }
    });

    if (!toggled) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const { data: newChildren, toggled } = traverseAndToggleNode(
          node.children,
          value,
          onChange,
        );
        if (toggled) {
          node.children = newChildren;
          break;
        }
      }
    }
  }
  return { data, toggled };
}

function toggleNodeSelected(
  value: string,
  setData: any,
  onChange: (node: BuildingCodeTreeNode) => void,
): void {
  setData((data: BuildingCodeTreeNode) => {
    const { data: newChildren, toggled } = traverseAndToggleNode(
      data.children,
      value,
      onChange,
    );
    return { ...data, children: newChildren };
  });
}

function setAllChildren(
  node: BuildingCodeTreeNode,
  value: boolean,
  onChange: (node: BuildingCodeTreeNode) => void,
) {
  let flat: BuildingCodeTreeNode[] = getFlattedChildren(node);

  flat.forEach((child: BuildingCodeTreeNode) => {
    child.isSelected = value;
    onChange(child);
  });
}

function traverseAndToggleNodeChildren(
  data: BuildingCodeTreeNode[],
  value: string,
  onChange: (node: BuildingCodeTreeNode) => void,
) {
  let toggled = false;
  data.forEach((node) => {
    if (node.value === value) {
      toggled = true;
      let newValue = true;
      // If all selected, change them to false
      if (isAllSelected(node)) {
        newValue = false;
      }

      node.isSelected = newValue;
      onChange(node);
      setAllChildren(node, newValue, onChange);
    }
  });

  if (!toggled) {
    for (let i = 0; i < data.length; i++) {
      let node = data[i];
      const { data: newChildren, toggled } = traverseAndToggleNodeChildren(
        node.children,
        value,
        onChange,
      );
      node.children = newChildren;
      if (toggled) {
        break;
      }
    }
  }

  return { data, toggled };
}

function toggleAllChildren(
  value: string,
  setData: any,
  onChange: (node: BuildingCodeTreeNode) => void,
) {
  setData((data: BuildingCodeTreeNode) => {
    const { data: newChildren } = traverseAndToggleNodeChildren(
      data.children,
      value,
      onChange,
    );
    const newData = { ...data, children: newChildren };
    return newData;
  });
}

export function CheckItem({
  node,
  setData,
  onChange,
}: {
  node: BuildingCodeTreeNode;
  setData: (node: BuildingCodeTreeNode) => void;
  onChange: (node: BuildingCodeTreeNode) => void;
}) {
  const allSelected = isAllSelected(node);
  const hasChildren = node.children.length;
  return (
    <div className="item">
      <div className={classNames("top-item", { parent: hasChildren })}>
        <label>
          <input
            type="checkbox"
            checked={node.isSelected}
            disabled={node.isDisabled}
            onChange={(evt) => {
              toggleNodeSelected(node.value, setData, onChange);
            }}
          />
          <span>{node.value}</span>
        </label>
        {!!hasChildren && (
          <div className="select-all">
            <label>
              <input
                type="checkbox"
                disabled={node.isDisabled}
                onChange={() =>
                  toggleAllChildren(node.value, setData, onChange)
                }
                checked={allSelected}
              />
              <span>{allSelected ? "Unselect" : "Select"} section</span>
            </label>
          </div>
        )}
      </div>
      <div className="children">
        <NestedChecklistItem
          data={node.children}
          setData={setData}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export function NestedChecklistItem({
  data,
  setData,
  onChange,
}: {
  data: BuildingCodeTreeNode[];
  setData: (node: BuildingCodeTreeNode) => void;
  onChange: (node: BuildingCodeTreeNode) => void;
}) {
  return (
    <>
      {data.map((node) => (
        <CheckItem node={node} setData={setData} onChange={onChange} />
      ))}
    </>
  );
}

function isAllSelected(node: BuildingCodeTreeNode) {
  let allSelected = true;

  let flat: BuildingCodeTreeNode[] = getFlattedChildren(node);

  flat.forEach((child: BuildingCodeTreeNode) => {
    if (!child.isSelected) {
      allSelected = false;
    }
  });

  return allSelected;
}

function getFlattedChildren(
  node: BuildingCodeTreeNode,
): BuildingCodeTreeNode[] {
  let flat = [node];
  node.children.forEach((child) => {
    flat = [...flat, ...getFlattedChildren(child)];
  });
  return flat;
}

interface NestedChecklistProps {
  data: BuildingCodeTreeNode;
  onChange: (node: BuildingCodeTreeNode) => void;
}

export const NestedChecklist = ({ data, onChange }: NestedChecklistProps) => {
  const [checklistData, setChecklistData] =
    useState<BuildingCodeTreeNode>(data);
  const allSelected = isAllSelected(checklistData);

  const labelStr = data.label + "";
  const chapterNumber =
    data.label.length < 4 ? labelStr.slice(0, 1) : labelStr.slice(0, 2);

  useEffect(() => {
    if (checklistData) {
      onChange(data);
    }
  }, [checklistData, onChange, data]);

  return (
    <div className="nested-list">
      <div>
        <label>
          <input
            type="checkbox"
            checked={allSelected}
            onChange={() => {
              setChecklistData((data) => {
                const flat = getFlattedChildren(data);
                flat.forEach((node) => {
                  node.isSelected = !allSelected;
                  onChange(node);
                });
                return { ...data };
              });
            }}
          />
          <span className="chapter-header">
            {allSelected ? "Unselect chapter " : "Select chapter "}
            {chapterNumber}
          </span>
        </label>
      </div>
      <NestedChecklistItem
        data={checklistData.children}
        setData={setChecklistData}
        onChange={onChange}
      />
    </div>
  );
};
