import React, { useEffect, useMemo, useRef, useState } from "react";
import { QuestionBox } from "./QuestionBox";
import "./inquiryQa.scss";
import { questionApi } from "../../../api/questionApi";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { currenPlanQa } from "../../../atom/planQaAtom";
import { questionsAtom } from "../../../atom/questionAtom";
import { DataState } from "../../../atom/projectsAtom";
import { EmptyContainer } from "../../other/EmptyContainer";
import { MultiLinearLoader } from "../../other/MultiLinearLoader";
import { LinearLoader } from "../../other/LinearLoader";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import moment from "moment";
import { MarkdownCitation } from "./MarkdownCitation";
import {
  Analysis,
  CityName,
  PlanQuestion,
  SourceItem,
} from "../../../model/blueprintServer";
import { Button, ButtonColor } from "../../theme/Button";
import { FaAngleDown, FaAngleUp, FaQuestion } from "react-icons/fa";
import { useFetchBuildingCode } from ".././hook/useFetchBuildingCode";
import { BuildingCodeCard } from "./BuildingCodeCard";
import classNames from "classnames";

export const TEMP_CODE_NAME = "NYC_2022";

const extractCodeSections = (data: PlanQuestion[]) =>
  data.reduce(
    (prev, current) => [
      ...prev,
      ...current.answer.relevantSections.map((s) => s.section),
    ],
    [] as string[],
  );

export const PlanQaPage = () => {
  const [questions, setQuestions] = useRecoilState(questionsAtom);
  const resetQuestions = useResetRecoilState(questionsAtom);
  const currentPlanQa = useRecoilValue(currenPlanQa);
  const [localLoading, setLocalLoading] = useState(false);
  const { model } = useRecoilValue(currentProjectsAtom);
  const fetchCode = useFetchBuildingCode();

  useEffect(() => {
    if (!currentPlanQa.data || !model) {
      return;
    }

    if (questions.state === DataState.NOT_LOADED) {
      setQuestions((old) => ({ ...old, state: DataState.LOADING }));
      questionApi.getAll(currentPlanQa.data.planQaId).then((data) => {
        setQuestions((old) => ({ ...old, state: DataState.LOADED, data }));
        const codes = data.reduce(
          (prev, current) => [
            ...prev,
            ...current.answer.relevantSections.map((s) => s.section),
          ],
          [] as string[],
        );
        fetchCode(
          model.jurisdiction.cityName,
          model.jurisdiction.codeYear,
          codes,
        );
      });
    }
  }, [
    questions,
    setQuestions,
    resetQuestions,
    currentPlanQa.data,
    fetchCode,
    model,
  ]);

  const handleQuestion = (question: string, onSubmit: () => void) => {
    if (!currentPlanQa.data || !model) {
      return;
    }

    setLocalLoading(true);
    questionApi
      .ask(currentPlanQa.data.planQaId, question)
      .then((data) => {
        setQuestions((old) => ({
          ...old,
          data: [...old.data, data],
        }));
        fetchCode(
          model.jurisdiction.cityName,
          model.jurisdiction.codeYear,
          extractCodeSections([data]),
        );
      })
      .finally(() => {
        setLocalLoading(false);
        onSubmit();
      });
  };

  const items = useMemo(
    () =>
      questions.data.map((question, index) => {
        if (!model) {
          return null;
        }

        return (
          <AnswerBox
            key={index}
            question={question}
            city={model.jurisdiction.cityName}
            year={model.jurisdiction.codeYear}
          />
        );
      }),
    [questions, model],
  );

  return (
    <div className="inquiry-qa" id="inquiry-qa">
      <h2>Reviews</h2>
      <QuestionBox
        handleSubmit={handleQuestion}
        planId={currentPlanQa.data?.planId ?? ""}
        projectId={model?.projectId ?? ""}
      />
      <div className="items flex">
        <h3>History</h3>
        {localLoading && (
          <div className="loader">
            <LinearLoader />
          </div>
        )}
        {questions.state === DataState.LOADING && <MultiLinearLoader />}
        {!!items.length &&
          questions.state === DataState.LOADED &&
          items.reverse()}
        {!items.length && questions.state === DataState.LOADED && (
          <EmptyContainer>Ask a question</EmptyContainer>
        )}
      </div>
    </div>
  );
};

interface SourceProps {
  source: SourceItem;
  city: CityName;
  year: number;
}

const Source = ({ source, city, year }: SourceProps) => {
  const [showCard, setShowCard] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div
      className="source"
      ref={ref}
      onMouseEnter={() => setShowCard(true)}
      onMouseLeave={() => setShowCard(false)}
    >
      {showCard && (
        <BuildingCodeCard
          parentEl={ref.current}
          source={source}
          city={city}
          year={year}
        />
      )}
      <div className="title">{source.sectionTitle}</div>
      <div> {source.section}</div>
    </div>
  );
};

interface AnswerDetailsAnalysisProps {
  detailedAnalysis: Analysis[];
  show: boolean;
}

const DetailedAnalysis = ({
  detailedAnalysis,
  show,
}: AnswerDetailsAnalysisProps) => {
  return (
    <div className={classNames("detailed-analysis", { show })}>
      {detailedAnalysis.map((details, index) => (
        <div key={index}>
          <h4 className="consideration">
            <MarkdownCitation markdown={details.requirement} />
          </h4>
          <div className="analysis">
            <MarkdownCitation markdown={details.provideddata} />
          </div>
          <div className="analysis">
            <MarkdownCitation markdown={details.analysis} />
          </div>
          <div className="analysis">
            <MarkdownCitation markdown={details.evaluation} />
          </div>
        </div>
      ))}
    </div>
  );
};

interface AnswerProps {
  question: PlanQuestion;
  city: CityName;
  year: number;
}

export const AnswerBox = ({ question, city, year }: AnswerProps) => {
  const [showDetails, setShowDetails] = useState(false);

  const sources = question.answer.relevantSections.map((source, index) => (
    <Source source={source} key={index} city={city} year={year} />
  ));

  return (
    <div className="answer flex">
      <FaQuestion />
      <div className="box">
        <div className="content question">{question.question}</div>
        <h4>Sources</h4>
        <div className="content sources flex">{sources}</div>
        <h4 className="flex">Answer</h4>
        <div className="content">
          <MarkdownCitation markdown={question.answer.answer} />
        </div>
        <div>
          <Button
            color={ButtonColor.BRAND}
            IconLeft={showDetails ? FaAngleUp : FaAngleDown}
            onClick={() => setShowDetails((old) => !old)}
          >
            {showDetails ? "Hide details" : "Show details"}
          </Button>
        </div>
        <DetailedAnalysis
          show={showDetails}
          detailedAnalysis={question.answer.detailedAnalysis}
        />
      </div>
      <div className="date">
        {moment(new Date(question.created)).format("M/D/YYYY")}
      </div>
    </div>
  );
};
