import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { buildingCodeAtom } from "../../../atom/buildingCodeAtom";
import "./buildingCodeCard.scss";
import { usePositionRelativeToParent } from "../../../hooks/usePositionRelativeToParent";
import { CityName, SourceItem } from "../../../model/blueprintServer";
import { MultiLinearLoader } from "../../other/MultiLinearLoader";
import parse from "html-react-parser";
import { marked } from "marked";

interface BuildingCodeCardProps {
  parentEl: Element | null;
  source: SourceItem;
  city: CityName;
  year: number;
}

export const BuildingCodeCard = ({
  parentEl,
  source,
  city,
  year,
}: BuildingCodeCardProps) => {
  const position = usePositionRelativeToParent(parentEl);
  const { codes } = useRecoilValue(buildingCodeAtom);
  const buildingCode = useMemo(
    () => codes.get(city)?.get(source.section),
    [codes, source.section, city],
  );

  const details = useMemo(() => {
    if (!buildingCode) {
      return null;
    }

    return (
      <div>
        <div className="title">{buildingCode.title}</div>
        <div className="section">section {buildingCode.fullSectionPath}</div>
        <div>{parse(marked.parse(buildingCode.text) as string)}</div>
      </div>
    );
  }, [buildingCode]);

  return (
    <div className="building-code-card" style={{ ...position }}>
      <div className="inner">
        {buildingCode ? details : <MultiLinearLoader />}
      </div>
    </div>
  );
};
