import { PlanModel, ProjectModel } from "../model/blueprintServer";
import { BaseApi } from "./BaseApi";

class PlanApi extends BaseApi {
  async getPlans(projectId: string): Promise<PlanModel[]> {
    return this.get(`all?projectId=${projectId}`);
  }

  async addPlan(projectId: string, fildId: string): Promise<ProjectModel> {
    return this.get(`add?projectId=${projectId}&fileId=${fildId}`);
  }
}

export const planApi = new PlanApi("plan");
