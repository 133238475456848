import { atom } from "recoil";
import { ProjectModel } from "../model/blueprintServer";

export enum DataState {
  INITIAL,
  NOT_LOADED,
  LOADING,
  LOADED,
  FAILED,
}
export interface ProjectsAtom {
  state: DataState;
  list: ProjectModel[];
}
export const projectsAtom = atom<ProjectsAtom>({
  key: "ProjectsAtom",
  default: {
    state: DataState.NOT_LOADED,
    list: [],
  },
});
