import React, { useCallback, useMemo, ReactElement } from "react";
import { useRecoilState } from "recoil";
import { modalsAtom } from "../../atom/modelsAtom";
import { Modal } from "../theme/Modal";

export interface ModalContentComponent {
  handleClose: () => void;
}

export const useModal = () => {
  const [ModalInnerComponent, setModalInnerCoponent] =
    useRecoilState(modalsAtom);

  const showModal = (Component: React.ComponentType<ModalContentComponent>) => {
    setModalInnerCoponent(() => Component);
  };

  const hideModal = useCallback(
    () => setModalInnerCoponent(null),
    [setModalInnerCoponent],
  );

  const modalComponent = useMemo(() => {
    if (!ModalInnerComponent) {
      return null;
    }

    return (
      <Modal handleBackdropClick={hideModal}>
        <ModalInnerComponent handleClose={hideModal} />
      </Modal>
    );
  }, [ModalInnerComponent, hideModal]);

  return {
    showModal,
    hideModal,
    isShown: !!ModalInnerComponent,
    modalComponent,
  };
};
