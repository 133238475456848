export interface BuildingCodeTreeNode {
  label: string;
  value: string;
  isSelected: boolean;
  isDisabled: boolean;
  isCollapsed: boolean;
  children: BuildingCodeTreeNode[];
}

const insertPath = (root: BuildingCodeTreeNode[], path: string): void => {
  const levels = path.split(".");
  let currentNodeList = root;
  let currentPath = "";

  levels.forEach((level, index) => {
    currentPath = currentPath ? `${currentPath}.${level}` : level;
    let existingNode = currentNodeList.find((node) => node.label === level);

    if (!existingNode) {
      existingNode = {
        label: level,
        value: currentPath,
        isSelected: false,
        isDisabled: false,
        isCollapsed: false,
        children: [],
      };
      currentNodeList.push(existingNode);
    }

    currentNodeList = existingNode.children;
  });
};

export function createBuildingCodeSectionTree(
  paths: string[],
): BuildingCodeTreeNode[] {
  const root: BuildingCodeTreeNode[] = [];

  paths.forEach((path) => {
    insertPath(root, path);
  });

  return root;
}
