import { createBrowserRouter } from "react-router-dom";
import { Page } from "./Page";
import { Contact } from "../contact/Contact";
import { Privacy } from "../privacyAndTerms/Privacy";
import { Terms } from "../privacyAndTerms/Terms";
import { Page404 } from "./Page404";
import { CreateProject } from "../projects/project/CreateProject";
import { PlanInquiry } from "../projects/plan/PlanInquiry";
import { ROUTER_PATHS } from "./routerPaths";
import { PlanContainer } from "../projects/plan/PlanContainer";
import { PlanQaPage } from "../projects/questions/PlanQaPage";
import { Projects } from "../projects/project/Projects";
import { Project } from "../projects/project/Project";
import { AdminPlanViewer } from "../admin/AdminPlanViewer";
import { AdminReportUI } from "../admin/AdminReportUI";
import { PlanReport } from "../planReport/PlanReport";
import { PlanReportPage } from "../planReport/PlanReportPage";
export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Page />
      </>
    ),
    children: [
      { path: "", element: <Projects /> },
      { path: ROUTER_PATHS.ROOT, element: <Projects /> },
      { path: ROUTER_PATHS.PROJECTS_CREATE, element: <CreateProject /> },
      { path: ROUTER_PATHS.PROJECT, element: <Project /> },
      {
        path: ROUTER_PATHS.PLAN,
        element: <PlanContainer />,
      },
      {
        path: ROUTER_PATHS.PLAN_QA_ANNOTATION,
        element: <PlanInquiry />,
      },
      {
        path: ROUTER_PATHS.PLAN_QA,
        element: <PlanQaPage />,
      },
      {
        path: ROUTER_PATHS.PLAN_REPORT,
        element: <PlanReportPage />,
      },
      { path: "contact", element: <Contact /> },
      { path: "privacy", element: <Privacy /> },
      { path: "terms", element: <Terms /> },

      { path: "admin/plan", element: <AdminPlanViewer /> },
      { path: "admin/report", element: <AdminReportUI /> },
    ],
    errorElement: <Page404 />,
  },
]);
