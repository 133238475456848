import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect } from "react";
import { authApi } from "../api/authApi";
import { useRecoilState } from "recoil";
import { serverAuthFinishedAtom } from "../atom/serverAuthFinishedAtom";
import { DataState } from "../atom/projectsAtom";
import {
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ROUTER_PATHS } from "../components/app/routerPaths";

export const useAuth = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0();
  const [serverAuthState, setServerAuthState] = useRecoilState(
    serverAuthFinishedAtom,
  );
  const [searchParams] = useSearchParams();
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const doAuth = useCallback(() => {
    const asyncFn = async () => {
      try {
        const token = await getAccessTokenSilently();
        if (token) {
          setServerAuthState({
            state: DataState.LOADING,
            appLevelUser: undefined,
          });
          const appUser = await authApi.auth(token);
          setServerAuthState({
            state: DataState.LOADED,
            appLevelUser: appUser,
          });
        } else if (!matchPath({ path: ROUTER_PATHS.ROOT }, location.pathname)) {
          navigate(ROUTER_PATHS.HOMEPAGE);
        }
      } catch (e) {
        loginWithRedirect({ appState: { form: true } });
      }
    };

    asyncFn();
  }, [
    getAccessTokenSilently,
    setServerAuthState,
    loginWithRedirect,
    navigate,
    location.pathname,
  ]);

  useEffect(() => {
    if (searchParams.get("error") === "invalid_request") {
      setServerAuthState({
        state: DataState.FAILED,
        appLevelUser: undefined,
      });
      localStorage.removeItem("authToken");
      logout();
    }
  }, [searchParams, logout, setServerAuthState]);

  return {
    isAuth: isAuthenticated && serverAuthState.state === DataState.LOADED,
    isLoading: isLoading || serverAuthState.state === DataState.LOADING,
    user,
    doAuth,
    logout,
  };
};
