import React, { useCallback, useEffect, useRef, useState } from "react";
import "./canvasMenu.scss";
import { FaLeftLong, FaRegTrashCan, FaRightLong } from "react-icons/fa6";
import { planQaApi } from "../../api/PlanQaApi";
import { useRecoilState, useRecoilValue } from "recoil";
import { currenPlanQa } from "../../atom/planQaAtom";
import { DataState } from "../../atom/projectsAtom";
import { SpinnerLoader } from "../other/SpinnerLoader";
import { Button, ButtonColor } from "../theme/Button";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "../app/routerPaths";
import { currentProjectsAtom } from "../../atom/currentProjectAtom";
import classNames from "classnames";
import { pageImageSizeAtom } from "../../atom/pageImageSizeAtom";

interface CanvasRectMenuProps {
  annotationKey: string | null;
  resetAnnotationKey: () => void;
  menuRef: React.MutableRefObject<HTMLDivElement | null>;
  float: boolean;
}

export const CanvasMenu = ({
  annotationKey,
  resetAnnotationKey,
  menuRef,
  float,
}: CanvasRectMenuProps) => {
  const [inqueryOnPlan, setInqueryOnPlan] = useRecoilState(currenPlanQa);
  const [localLoading, setLocalLoading] = useState(false);
  const currentProject = useRecoilValue(currentProjectsAtom);
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const { containerWidth } = useRecoilValue(pageImageSizeAtom);

  useEffect(() => {
    setTitle(inqueryOnPlan.data?.title || "");
  }, [setTitle, inqueryOnPlan.data?.title]);

  const handleRemoveAnnotation = useCallback(() => {
    if (!inqueryOnPlan.data || !annotationKey) {
      return;
    }

    setLocalLoading(true);
    planQaApi
      .removeAnnotation(inqueryOnPlan.data.planQaId, annotationKey)
      .then((data) => {
        setInqueryOnPlan((old) => ({
          ...old,
          data,
          state: DataState.LOADED,
        }));
        setLocalLoading(false);
        resetAnnotationKey();
      });
  }, [
    inqueryOnPlan.data,
    annotationKey,
    setInqueryOnPlan,
    setLocalLoading,
    resetAnnotationKey,
  ]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === "Backspace") {
        handleRemoveAnnotation();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleRemoveAnnotation]);

  const handleChangeTitle = useCallback(
    (title: string) => {
      if (!inqueryOnPlan.data) {
        return;
      }

      setTitle(title);
      planQaApi.updateTitle(inqueryOnPlan.data?.planQaId, title);
      setInqueryOnPlan((old) => {
        if (!old.data) {
          return old;
        }

        return { ...old, data: { ...old.data, title } };
      });
    },
    [inqueryOnPlan.data, setInqueryOnPlan],
  );

  const handleTitleInputBlur = () => {
    if (title.length === 0) {
      handleChangeTitle("Untitled inquiry");
    }
  };

  return (
    <div
      className={classNames("canvas-rect-menu flex", { float })}
      ref={menuRef}
      style={{
        width: float ? containerWidth : "auto",
      }}
    >
      <Button
        color={ButtonColor.TRANSPARENT}
        classes="remove-annotation"
        onClick={() =>
          navigate(
            "/" +
              generatePath(ROUTER_PATHS.PLAN, {
                projectId: currentProject.model?.projectId,
                planId: inqueryOnPlan.data?.planId,
              }),
          )
        }
        IconLeft={FaLeftLong}
      >
        Back
      </Button>
      <Button
        color={ButtonColor.TRANSPARENT}
        classes="remove-annotation"
        onClick={handleRemoveAnnotation}
        disabled={!annotationKey}
        IconLeft={localLoading ? SpinnerLoader : FaRegTrashCan}
      >
        Remove
      </Button>
      <input
        value={title}
        onChange={(e) => handleChangeTitle(e.target.value)}
        onBlur={handleTitleInputBlur}
      />
      <Button
        color={ButtonColor.BRAND}
        classes="continue"
        onClick={() =>
          navigate(
            "/" +
              generatePath(ROUTER_PATHS.PLAN_QA, {
                projectId: currentProject.model?.projectId,
                planId: inqueryOnPlan.data?.planId,
                planQaId: inqueryOnPlan.data?.planQaId,
              }),
          )
        }
        IconRight={FaRightLong}
      >
        Ask a question
      </Button>
    </div>
  );
};
