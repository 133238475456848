import { useCallback } from "react";
import { DataState } from "../../../atom/projectsAtom";
import { planQaApi } from "../../../api/PlanQaApi";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "../../app/routerPaths";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currenPlanQa } from "../../../atom/planQaAtom";
import { useLoadPlanQaList } from ".././hook/useLoadPlanQaList";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { useCurrentPlan } from ".././hook/useCurrentPlan";

export const useCreatePlanQa = () => {
  const plan = useCurrentPlan();
  const loadInquiries = useLoadPlanQaList(false);
  const setInqueryOnPlan = useSetRecoilState(currenPlanQa);
  const currentProject = useRecoilValue(currentProjectsAtom);
  const navigate = useNavigate();

  return useCallback(() => {
    if (!plan) {
      return;
    }

    setInqueryOnPlan(() => ({
      state: DataState.LOADING,
      data: null,
    }));
    planQaApi.create(plan.id).then((inquery) => {
      setInqueryOnPlan(() => ({
        state: DataState.LOADED,
        data: inquery,
      }));
      loadInquiries(plan.id);
      const path = generatePath(ROUTER_PATHS.PLAN_QA_ANNOTATION, {
        projectId: currentProject.model?.projectId,
        planId: plan.id,
        planQaId: inquery.planQaId,
      });
      navigate("/" + path);
    });
  }, [
    setInqueryOnPlan,
    plan,
    loadInquiries,
    navigate,
    currentProject.model?.projectId,
  ]);
};
