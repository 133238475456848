import React from "react";
import { ProjectDetails } from "./ProjectDetails";
import { PlansList } from "./PlansList";
import "./project.scss";
import { useRecoilValue } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { AppLogoLoader } from "../../other/AppLogoLoader";

export const Project = () => {
  const currentProject = useRecoilValue(currentProjectsAtom);

  if (!currentProject.model) {
    console.log("Show loader");
    return <AppLogoLoader />;
  }

  return (
    <div className="flex project">
      <ProjectDetails />
      <PlansList />
    </div>
  );
};
