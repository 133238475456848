import { atom } from "recoil";
import { DataState, ProjectsAtom } from "./projectsAtom";
import {
  PlanQA,
  PlanModel,
  ProjectModel,
  PlanReport,
} from "../model/blueprintServer";
import { BaseAtomDataI, createDataAtom, useData } from "./AtomDataClass";

export interface CurrentPlanReportAtom {
  state: DataState;
  model: PlanReport | null;
}

export const currentPlanReportAtom = createDataAtom<PlanReport>(
  "currentPlanReportAtom",
);

export const useCurrentPlanReportData = () =>
  useData<PlanReport>(currentPlanReportAtom);
