import React, { useCallback, useMemo } from "react";
import { planQaListAtom } from "../../../atom/planQaAtom";
import { useRecoilValue } from "recoil";
import { DataState } from "../../../atom/projectsAtom";
import { generatePath, Link, useParams } from "react-router-dom";
import { MdFrontLoader, MdOutlineReviews } from "react-icons/md";
import { Button, ButtonColor } from "../../theme/Button";
import { DataLoadingStates, PlanModel } from "../../../model/blueprintServer";
import { MultiLinearLoader } from "../../other/MultiLinearLoader";
import { EmptyContainer } from "../../other/EmptyContainer";
import { FaPlus } from "react-icons/fa";
import { useModal } from "../../theme/useModal";
import { CreateAnalysisModal } from "./CreateAnalysisModal";
import { ROUTER_PATHS } from "../../app/routerPaths";
import { planReportsAtom } from "../../../atom/fullPlanAnalysisAtom";
import { SpinnerLoader } from "../../other/SpinnerLoader";
import { TbMessage2Question, TbReportAnalytics } from "react-icons/tb";

interface AnalysisListMenuProps {
  plan: PlanModel;
}

export const ReviewsMenu = ({ plan }: AnalysisListMenuProps) => {
  const planQaList = useRecoilValue(planQaListAtom);
  const planReports = useRecoilValue(planReportsAtom);
  const { projectId } = useParams();
  const { showModal } = useModal();

  const planQaItems = useMemo(() => {
    if (planQaList.state === DataState.LOADING) {
      return <MultiLinearLoader />;
    }

    if (planQaList.data.length === 0) {
      return null;
    }

    const reversedItems = [...planQaList.data].reverse(); // Flipping the item order
    return reversedItems.map((planQa) => (
      <Link
        className="item flex"
        to={
          "/" +
          generatePath(ROUTER_PATHS.PLAN_QA_ANNOTATION, {
            projectId,
            planId: plan.id,
            planQaId: planQa.planQaId,
          })
        }
        key={planQa.planQaId}
      >
        <TbMessage2Question size={18} />
        {planQa.title ? planQa.title : new Date(planQa.created).toDateString()}
      </Link>
    ));
  }, [planQaList, projectId, plan?.id]);

  const planReport = useMemo(() => {
    if (planReports.state === DataState.LOADING) {
      return <MultiLinearLoader />;
    }

    if (planReports.data.length === 0) {
      return <div>Create your first report</div>;
    }

    const reversedItems = [...planReports.data].reverse(); // Flipping the item order
    return reversedItems.map((planReport) => {
      const Icon =
        planReport.status == DataLoadingStates.COMPLETED
          ? TbReportAnalytics
          : SpinnerLoader;
      return (
        <Link
          className="item flex"
          to={
            "/" +
            generatePath(ROUTER_PATHS.PLAN_REPORT, {
              projectId,
              planId: plan.id,
              reportId: planReport.reportId,
            })
          }
          key={planReport.reportId}
        >
          <Icon size={18} /> {planReport.title}
        </Link>
      );
    });
  }, [projectId, plan?.id, planReports]);

  const getActionButton = useCallback(
    (label: string) => (
      <Button
        classes="add-button"
        onClick={() => showModal(CreateAnalysisModal)}
        color={ButtonColor.BRAND}
        IconLeft={FaPlus}
      >
        {label}
      </Button>
    ),
    [showModal],
  );

  return (
    <div className="flex reviews-menu">
      {!planQaItems && !planReport ? (
        <EmptyContainer>
          No current reviews {getActionButton("Start a review")}
        </EmptyContainer>
      ) : (
        <>
          <h3>Plan Q&A</h3>
          <div>{planQaItems}</div>
          <h3>Full Plan Review</h3>
          <div>{planReport}</div>
        </>
      )}
    </div>
  );
};
