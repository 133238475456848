import { PlanModel } from "../../model/blueprintServer";
import React, { useState } from "react";
import { formatImageUrl } from "../../util/imageUtil";

export const AdminPlanUI = ({ plan }: { plan: PlanModel }) => {
  const [seeJson, setSeeJson] = useState(false);

  return (
    <div className="plan-container">
      <button className="see-json" onClick={() => setSeeJson((old) => !old)}>
        {seeJson ? "Hide JSON" : "See JSON"}
      </button>
      {seeJson && <pre>{JSON.stringify(plan, null, "\t")}</pre>}
      <div className="plan-details">
        <div className="plan-item">
          <strong>Plan Id:</strong> {plan.id}
        </div>
        <div className="plan-item">
          <strong>Project Id:</strong> {plan.projectId}
        </div>
        <div className="plan-item">
          <strong>Status:</strong> {plan.status}
        </div>
        <div className="plan-item">
          <strong>Title:</strong> {plan.title}
        </div>
        <div className="plan-item">
          <strong>Created:</strong> {new Date(plan.created).toDateString()}
        </div>
        <div className="plan-item">
          <strong>File URL</strong>:{" "}
          <a href={formatImageUrl(plan.originalFile)}>PDF</a>
        </div>
        <div className="project-pages">
          <strong>Components:</strong>
          {plan.pages.map((page, index) => (
            <div className="page-container" key={page.id}>
              <h2>Page {index}</h2>
              <div>
                <strong>Page ID:</strong> {page.id}
              </div>
              <div className="page-item">
                <img
                  src={formatImageUrl(page.pageImageBlob)}
                  className="page-image"
                />
                <h4>Components</h4>
                <table className="component-table">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Data</th>
                      <th>Categories</th>
                      <th>Description</th>
                      <th>Image</th>
                    </tr>
                  </thead>
                  <tbody>
                    {page.components
                      .filter((c) => c && c.data)
                      .map((c, index) => (
                        <tr key={index}>
                          <td>{c.data.title}</td>
                          <td>{c.data.extractedData}</td>
                          <td>{c.data.categories.join(", ")}</td>
                          <td>{c.data.description}</td>
                          <td>
                            <img
                              src={formatImageUrl(c.imageBlobStorage)}
                              className="component-image"
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
