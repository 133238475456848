import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { projectsAtom, DataState } from "../../../atom/projectsAtom";
import { projectApi } from "../../../api/projectApi";
import "./createProject.scss";
import { generatePath, useNavigate } from "react-router-dom";
import { AddressForm } from "../../other/AddressForm";
import { ROUTER_PATHS } from "../../app/routerPaths";

export const CreateProject = () => {
  const [projectStoreAtom, setProjectStoreAtom] = useRecoilState(projectsAtom);
  const [error, setError] = useState<String | null>(null);
  const navigate = useNavigate();

  const handleSubmit = (placeId: string) => {
    setProjectStoreAtom((old) => ({
      ...old,
      state: DataState.LOADING,
    }));
    projectApi
      .create(placeId)
      .then((project) => {
        setProjectStoreAtom((old) => ({
          state: DataState.LOADED,
          list: [...old.list, project],
        }));
        navigate(
          generatePath("/" + ROUTER_PATHS.PROJECT, {
            projectId: project.projectId,
          }),
        );
      })
      .catch(() => {
        setError("Failed to create address");
        setProjectStoreAtom((old) => ({
          ...old,
          state: DataState.LOADED,
        }));
      });
  };

  return (
    <div className="create-project">
      <h2>New project</h2>
      {error ? <div className="error">{error}</div> : ""}
      <div className="">
        <AddressForm handleSubmit={handleSubmit} />
      </div>
    </div>
  );
};
