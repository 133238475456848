import { useRecoilState, useSetRecoilState } from "recoil";
import { buildingCodeAtom } from "../../../atom/buildingCodeAtom";
import { BuildingCodeRecord, CityName } from "../../../model/blueprintServer";

export const useAddBuildingCode = () => {
  const setCodes = useSetRecoilState(buildingCodeAtom);

  return (cityName: CityName, buildingCode: BuildingCodeRecord) =>
    setCodes((old) => {
      if (!old.codes.get(cityName)) {
        old.codes.set(cityName, new Map());
      }
      old.codes.get(cityName)?.set(buildingCode.fullSectionPath, buildingCode);
      return old;
    });
};
