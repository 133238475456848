import React, { useMemo } from "react";
import { generatePath, Link } from "react-router-dom";
import { DataLoadingStates } from "../../../model/blueprintServer";
import classNames from "classnames";
import { formatImageUrl } from "../../../util/imageUtil";
import { DataState } from "../../../atom/projectsAtom";
import { LinearLoader } from "../../other/LinearLoader";
import { useRecoilState } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { UploadContainer } from "../../other/UploadContainer";
import { projectApi } from "../../../api/projectApi";
import { signedUrlApi } from "../../../api/signedUrlApi";
import "./plansList.scss";
import { isDataProcessing } from "../../../util/dataLoadingProcessing";
import { MultiLinearLoader } from "../../other/MultiLinearLoader";
import { planApi } from "../../../api/planApi";
import { ROUTER_PATHS } from "../../app/routerPaths";

export const PlansList = () => {
  const [currentProject, setCurrentProject] =
    useRecoilState(currentProjectsAtom);

  const plans = useMemo(() => {
    const hasLoadingPlans = currentProject.plans.some((p) =>
      isDataProcessing(p.status),
    );
    const items = currentProject.plans.map((plan) => (
      <Link
        to={
          plan.status === DataLoadingStates.COMPLETED
            ? "/" +
              generatePath(ROUTER_PATHS.PLAN, {
                projectId: currentProject.model?.projectId,
                planId: plan.id,
              })
            : ""
        }
        key={plan.id}
        className={classNames("plan", {
          loading: plan.status !== DataLoadingStates.COMPLETED,
        })}
      >
        <div className="top title">{plan.title}</div>
        <div className="top">{new Date(plan.created).toDateString()}</div>
        <div className="image">
          {isDataProcessing(plan.status) && <div>Processing</div>}
          {plan.status === DataLoadingStates.COMPLETED && plan.pages.length ? (
            <img src={formatImageUrl(plan.pages[0].thumbnailBlob)} />
          ) : null}
        </div>
      </Link>
    ));

    if (currentProject.state === DataState.LOADING) {
      return (
        <div className="plans-list-message">
          <div className="text">Uploading plans, might take a few minutes</div>
          <LinearLoader />
        </div>
      );
    }

    if (items.length) {
      return (
        <div className="plans">
          {hasLoadingPlans && (
            <div className="plans-list-message">
              Processing plans takes up to 24 hours to finish
            </div>
          )}
          <div className="grid">{items.reverse()}</div>
        </div>
      );
    }

    return <div className="plans-list-message">No plans yet</div>;
  }, [currentProject]);

  const handlePlanUpload = async (file: File) => {
    if (!currentProject.model?.projectId) {
      return;
    }

    setCurrentProject((old) => ({ ...old, plansState: DataState.LOADING }));
    const projectId = currentProject.model.projectId;
    const signedUrlResponse = await signedUrlApi.getSignedUrl();
    await signedUrlApi.uploadFile(file, signedUrlResponse.signedUrl);
    const model = await planApi.addPlan(projectId, signedUrlResponse.id);
    const plans = await planApi.getPlans(projectId);
    setCurrentProject((old) => ({
      ...old,
      plans,
      model,
      plansState: DataState.LOADED,
    }));
  };

  const isLoading = useMemo(
    () => currentProject.plansState !== DataState.LOADED,
    [currentProject],
  );

  return (
    <div className="plan-list">
      <h2>Plans</h2>
      <UploadContainer handleFile={handlePlanUpload} />
      {isLoading ? <MultiLinearLoader /> : plans}
    </div>
  );
};
