import React, { useEffect, useMemo } from "react";
import { useRecoilState } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { useNavigate, useParams } from "react-router-dom";
import { PlanViewer } from "./PlanViewer";
import "./planInquiry.scss";

export const PlanInquiry = () => {
  const [currentProject, setCurrentProject] =
    useRecoilState(currentProjectsAtom);
  const { planId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!planId) {
      navigate("/projects");
    }
  }, [planId, navigate]);

  const plan = useMemo(() => {
    const plan = currentProject.plans.find((p) => p.id === planId);
    if (!plan) {
      navigate("/projects");
      return null;
    }

    return plan;
  }, [currentProject.plans, navigate, planId]);

  if (!plan) {
    return null;
  }

  return (
    <div className="project-inquiry">
      <PlanViewer plan={plan} />
    </div>
  );
};
