import { useRecoilValue } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  BuildingCodeTreeNode,
  createBuildingCodeSectionTree,
} from "../../../util/createBuildingCodeSectionTree";
import { buildingCodeApi } from "../../../api/buildingCodeApi";
import { CityName } from "../../../model/blueprintServer";
import { NestedChecklist } from "../../other/NestedChecklist";
import { Button, ButtonColor, ButtonSize } from "../../theme/Button";
import { useCreatePlanReport } from "./useCreatePlanReport";
import { planReportsAtom } from "../../../atom/fullPlanAnalysisAtom";
import { DataState } from "../../../atom/projectsAtom";
import { MultiLinearLoader } from "../../other/MultiLinearLoader";

interface ChapterSelectorProps {
  handleClose: () => void;
}

export const CreatePlanReport = ({ handleClose }: ChapterSelectorProps) => {
  const currentProject = useRecoilValue(currentProjectsAtom);
  const [checklistData, setChecklistData] = useState<BuildingCodeTreeNode[]>(
    [],
  );
  const [selectedItems, setSelectedItems] = useState<{
    [val: string]: boolean;
  }>({});
  const { state } = useRecoilValue(planReportsAtom);
  const createReport = useCreatePlanReport();

  useEffect(() => {
    const fetch = async () => {
      const data = await buildingCodeApi.getSectionsList(
        currentProject.model?.jurisdiction.cityName ?? CityName.NEW_YORK_CITY,
        currentProject.model?.jurisdiction.codeYear ?? 0,
      );
      setChecklistData(createBuildingCodeSectionTree(data));
    };
    fetch();
  }, [currentProject, setChecklistData]);

  const handleChange = useCallback(
    (node: BuildingCodeTreeNode) => {
      setSelectedItems((items) => {
        const { isSelected } = node;
        const newItems = { ...items, [node.value]: isSelected };

        if (!isSelected) {
          delete newItems[node.value];
        }
        return newItems;
      });
    },
    [setSelectedItems],
  );

  const forest = useMemo(() => {
    return checklistData?.map((tree, index) => (
      <NestedChecklist data={tree} key={index} onChange={handleChange} />
    ));
  }, [checklistData, handleChange]);

  const handleSubmit = async () => {
    await createReport(Object.keys(selectedItems));
    handleClose();
  };

  let inner;
  if (!checklistData.length) {
    inner = <div>Loading</div>;
  } else if (state === DataState.LOADING) {
    inner = (
      <div>
        <MultiLinearLoader />
      </div>
    );
  } else {
    inner = <div className="checklist-container">{forest}</div>;
  }

  return (
    <div className="chapter-selector">
      <div className="header">
        <h2>Select chapters</h2>
      </div>
      {inner}
      <div className="footer">
        <Button
          onClick={() => handleSubmit()}
          size={ButtonSize.LARGE}
          color={ButtonColor.BRAND}
          disabled={
            !Object.keys(selectedItems).length || state !== DataState.LOADED
          }
        >
          Create report
        </Button>
      </div>
    </div>
  );
};
