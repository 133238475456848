import { useCallback } from "react";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { planQaListAtom } from "../../../atom/planQaAtom";
import { DataState } from "../../../atom/projectsAtom";
import { planQaApi } from "../../../api/PlanQaApi";
import { questionsAtom } from "../../../atom/questionAtom";

export const useLoadPlanQaList = (showLoadingState = true) => {
  const setInqueryList = useSetRecoilState(planQaListAtom);
  const resetQuestions = useResetRecoilState(questionsAtom);

  return useCallback(
    (planId: string) => {
      if (showLoadingState) {
        setInqueryList((old) => ({ ...old, state: DataState.LOADING }));
      }

      resetQuestions();
      planQaApi.getAll(planId).then((inqueryList) =>
        setInqueryList(() => ({
          data: inqueryList,
          state: DataState.LOADED,
        })),
      );
    },
    [setInqueryList, showLoadingState, resetQuestions],
  );
};
