import React, { ReactNode, useMemo, useRef, useState } from "react";
import "./dropdown.scss";
import { FaAngleDown } from "react-icons/fa";
import { IconType } from "react-icons";
import classNames from "classnames";
import { usePositionRelativeToParent } from "../../hooks/usePositionRelativeToParent";

interface DropdownProps<T> {
  value: T;
  values: T[];
  onChange: (val: T) => void;
  valueFn?: (val: T) => string;
  searchFn?: (item: T, query: string) => boolean;
  IconLeft?: IconType;
}

export const Dropdown = <T extends ReactNode>({
  values,
  value,
  onChange,
  valueFn,
  searchFn,
  IconLeft,
}: DropdownProps<T>) => {
  const [visible, setVisible] = useState(false);
  const [query, setQuery] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const position = usePositionRelativeToParent(ref.current);

  const filterValues = useMemo(() => {
    if (!searchFn) {
      return values;
    }

    return values.filter((item) => searchFn(item, query));
  }, [values, searchFn, query]);

  return (
    <div
      className="dropdown"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      ref={ref}
    >
      <span className="value-container">
        <div className="value flex">
          {IconLeft && <IconLeft className={classNames({ text: !!value })} />}
          <span>{valueFn ? valueFn(value) : value}</span>
        </div>
        <FaAngleDown />
      </span>

      {visible && (
        <div className="dropdown-content" style={{ ...position }}>
          <div className="inner">
            {searchFn && (
              <input
                onMouseEnter={(e) => (e.target as HTMLDivElement).focus()}
                placeholder="Search"
                onChange={(e) => setQuery(e.target.value)}
              />
            )}
            <div className="list">
              {filterValues.map((option, i) => (
                <div
                  className="item"
                  key={i}
                  onClick={() => {
                    onChange(option);
                    setVisible(false);
                  }}
                >
                  {valueFn ? valueFn(option) : option}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
